<app-snow [mobile]="true"></app-snow>
<div class="container">
    <div class="mobile-header__wrapper">

        <button class="mobile-header__menu-button" type="button" (click)="menu.open()">
            <app-icon icon="menu-28"></app-icon>
        </button>

        <div class="mobile-header__logo">
            <app-logo [mobile]="true"></app-logo>
        </div>

        <div
            class="mobile-header__search mobile-search"
            #searchForm
        >
            <form class="mobile-search__body" (submit)="$event.preventDefault(); onSearch(searchInput.value)">
                <label class="sr-only" for="mobile-site-search">{{ 'INPUT_SEARCH_LABEL'|translate }}</label>
                <input
                    type="text"
                    id="mobile-site-search"
                    class="mobile-search__input"
                    placeholder="{{'INPUT_SEARCH_PLACEHOLDER'|translate}}"
                    #searchInput
                >
                <button type="submit" class="mobile-search__button mobile-search__button--search px-2">
                    <app-icon icon="search-20"></app-icon>
                </button>

                <div class="mobile-search__field"></div>
            </form>
        </div>

        <div class="mobile-header__indicators">
            <div *ngIf="garage.hasCars$|async; else emptyGarage" class="mobile-indicator mobile-indicator__garage">
                <button class="mobile-indicator__button" (click)="toggleChangeVehicle()">
                    <span class="mobile-indicator__icon">
                        <app-icon icon="garage-46"></app-icon>
                    </span>
                    <span class="mobile-indicator__counter">
                            {{ (garage.cars$|async)?.length?.toString() || '0' }}
                    </span>
                </button>
            </div>

            <ng-template #emptyGarage>
                <div class="mobile-indicator mobile-indicator__empty-garage">
                    <button
                        class="mobile-indicator__button mobile-indicator__button--rounded"
                        appAnchorRouter
                        [anchorId]="'carPicker'"
                        [fallbackRoute]="'/'"
                        [offsetY]="140"
                        (click)="garage.setPickerState(true)"
                    >
                        <span class="mobile-indicator__icon">
                            <app-icon icon="car-24"></app-icon>
                        </span>
                    </button>
                </div>
            </ng-template>

            <div class="mobile-indicator">
                <button class="mobile-indicator__button" (click)="cart.open()">
                    <span class="mobile-indicator__icon">
                        <app-icon icon="cart-19"></app-icon>
                    </span>
                    <span
                        *ngIf="cart.quantity$|async"
                        class="mobile-indicator__counter"
                    >
                            {{ cart.quantity$|async }}
                        </span>
                </button>
            </div>
        </div>

        <div class="mobile-indicator mobile-header__contacts">
            <a
                class="mobile-indicator__button mobile-indicator__button--contrast"
                [routerLink]="'/site/contact-us'|localize"
            >
                    <span class="mobile-indicator__icon">
                        <app-icon icon="phone-20"></app-icon>
                    </span>
            </a>
        </div>

    </div>
</div>

<app-garage-vehicle-selector
    [wrapAsPopUp]="true"
    [showPopUp]="changeVehicleShowPopUp"
    (selected)="closeChangeVehicle()"
></app-garage-vehicle-selector>
