import {
    AfterViewInit,
    Component,
    ElementRef, HostBinding,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';
import { ShopApi } from '../../../../api';
import { fromEvent, Observable, Subject, throwError } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { Product } from '../../../../interfaces/product';
import { ShopCategory } from '../../../../interfaces/category';
import { UrlService } from '../../../../services/url.service';
import { isPlatformBrowser } from '@angular/common';
import { fromOutsideClick } from '../../../../functions/rxjs/from-outside-click';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy$: Subject<void> = new Subject<void>();

    query$: Subject<string> = new Subject<string>();

    inSearch = false;

    suggestionsIsOpen = false;

    hasSuggestions = false;

    locSuggestions = false;

    searchPlaceholder$!: Observable<string>;

    products: Product[] = [];

    categories: ShopCategory[] = [];

    @HostBinding('class.search') classSearch = true;

    get element(): HTMLElement { return this.elementRef.nativeElement; }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private zone: NgZone,
        private shopApi: ShopApi,
        private translate: TranslateService,
        private elementRef: ElementRef,
        public url: UrlService,
        private router: Router,
        private localize: LocalizeRouterService,
        // public garage: GarageService,
    ) { }

    ngOnInit(): void {

        this.query$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
                this.inSearch = true;
                this.hasSuggestions = false;
            }),
            switchMap(query => this.shopApi.getSearchSuggestions(query, {
                limitProducts: 8,
                limitCategories: 4,
            })),
            takeUntil(this.destroy$),
            catchError(err => {
                this.inSearch = false;
                return throwError(() => err);
            }),
        ).subscribe(result => {
            this.inSearch = false;
            if (result.products.length === 0 && result.categories.length === 0) {
                this.hasSuggestions = false;
                return;
            }

            this.hasSuggestions = true;
            this.products = result.products;
            this.categories = result.categories;
            if (!this.locSuggestions) {
                this.toggleSuggestions(true);
            }
        });

        // this.searchPlaceholder$ = this.garage.currentVehicle$.pipe(
        //     switchMap(vehicle => {
        //         if (vehicle) {
        //             return this.translate.stream('INPUT_SEARCH_PLACEHOLDER_VEHICLE', vehicle);
        //         }
        //
        //         return this.translate.stream('INPUT_SEARCH_PLACEHOLDER');
        //     }),
        // );

        this.searchPlaceholder$ = this.translate.stream('INPUT_SEARCH_PLACEHOLDER');
    }

    // searchByCar() {
    //     const route = this.localize.translateRoute(this.url.allProducts());
    //     this.router.navigateByUrl(route as string).then();
    // }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            fromOutsideClick(this.element).pipe(
                // filter(() => this.suggestionsIsOpen),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                this.zone.run(() => this.toggleSuggestions(false));
            });

            fromEvent(this.element, 'focusout').pipe(
                debounceTime(10),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                if (document.activeElement === document.body) {
                    return;
                }

                // Close suggestions if the focus received an external element.
                if (document.activeElement && document.activeElement.closest('.search') !== this.element) {
                    this.zone.run(() => this.toggleSuggestions(false));
                }
            });
        });
    }

    search(query: string): void {
        this.query$.next(query);
    }

    toggleSuggestions(force?: boolean): void {
        this.suggestionsIsOpen = force !== undefined ? force : !this.suggestionsIsOpen;
    }

    onInput(event: Event): void {
        this.locSuggestions = false;

        const input = event.target as HTMLInputElement;
        this.search(input.value);
    }

    onInputFocus(event: FocusEvent): void {
        this.locSuggestions = false;

        const input = event.target as HTMLInputElement;
        this.toggleSuggestions(true);
        this.search(input.value);
    }

    onSearch(term: string) {
        //просьба снимать фильтр по машине при поиске
        // this.garage.setCurrentVehicle(null);

        /**
         * блокировка вывода предложений, нужна для ситуации, когда кнопка поиска или Enter были нажаты ранее,
         * чем отработал поиск с предложениями - в таком случае не нужно показывать предложения
         */
        this.locSuggestions = true;

        this.toggleSuggestions(false);
        if (term) {
            const route = this.localize.translateRoute(this.url.search(term));
            this.router.navigateByUrl(route as string).then();
        }
    }
}
