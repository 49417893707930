import { Observable } from 'rxjs';
import {
    BaseVehicle,
    FeaturedCars,
    ModificationVehicle,
    SearchVehicle,
    UserVehicle,
} from '../../interfaces/vehicle';

export abstract class VehicleApi {

    abstract getManufacturer(): Observable<BaseVehicle[]>;

    abstract getModelYears(brandId: number): Observable<BaseVehicle[]>;

    abstract getModelGroups(brandId: number, year?: number): Observable<BaseVehicle[]>;

    abstract getModels(brandId: number, year?: number, groupId?: number): Observable<BaseVehicle[]>;

    abstract getModifications(modelId: number, year?: number): Observable<ModificationVehicle[]>;

    abstract getGarage(): Observable<UserVehicle[]>;

    abstract saveUserCarData(car: UserVehicle): Observable<any>;

    abstract addVehicle(id: number, uid: string, vin: string, plate: string): Observable<any>;

    abstract removeVehicle(id: number, uid: string): Observable<any>;

    abstract getVehicleByVIN(vin: string): Observable<SearchVehicle>;

    abstract getVehicleByPlate(plate: string): Observable<SearchVehicle>;

    abstract getFeaturedCars(): Observable<FeaturedCars>;

    abstract getBrand(slug: string): Observable<BaseVehicle>;

    abstract getModel(slug: string): Observable<BaseVehicle>;

    abstract getCar(targetKeyword: string): Observable<ModificationVehicle>;

    abstract setCurrentVehicle(vehicle: UserVehicle|null): Observable<any>;

    abstract getCurrentVehicle(): Observable<UserVehicle|null>;
}
