<div class="garage-vehicle-selector__wrapper">
    <div
        *ngFor="let car of garage.cars$|async "
        class="garage-vehicle-selector__item"
    >
            <span
                class="car-content"
                role="button"
                (click)="selectVehicle(car)"
            >
                <div
                    *ngIf="car.uid === (garage.currentVehicle$|async)?.uid; else inactiveIndicator"
                    class="leading active"
                >
                    <app-icon icon="check-9"></app-icon>
                </div>

                <ng-template #inactiveIndicator>
                    <div class="leading"></div>
                </ng-template>

                <span class="title">{{ car.name }}</span>
            </span>

        <span role="button" (click)="removeVehicle(car)">
                <app-icon icon="cross-18"></app-icon>
        </span>
    </div>

    <button
        appAnchorRouter
        [anchorId]="'carPicker'"
        [fallbackRoute]="'/'"
        [offsetY]="150"
        class="btn btn-primary default-pargo-button-inverse justify-content-center"
        (click)="addNewVehicle()"
    >
        <app-icon icon="car-24"></app-icon>
        <span class="text-center">{{ 'BUTTON_ADD_VEHICLE_LONG' | translate }}</span>
    </button>
</div>
