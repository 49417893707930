import { BaseCategory } from '../interfaces/category';
import { GetProductsListOptions } from '../api/base';
import { Router } from '@angular/router';

export function getCategoryPath<T extends BaseCategory>(category: T): T[] {
    return category ? [...getCategoryPath(category.parent as T), category] : [];
}

export function parseRouteSegments(url: string) {
    return url.split('/');
}

export function parseProductsListParams(url: string): GetProductsListOptions {
    const options: GetProductsListOptions = {};

    const segments = parseRouteSegments(url);

    // const filterSegment = params['filter'];
    let filterSegment: string | undefined;
    segments.reverse().some((item) => {
        if (item.includes('=')) {
            filterSegment = item;
            return true; // Прерывает цикл
        }
        return false; // Продолжает цикл
    });

    if (filterSegment) {
        const filterPairs = filterSegment.split(';');

        filterPairs.forEach((pair: string) => {
            const [key, value] = pair.split('=');
            if (key && value) {
                switch (key) {
                    case 'page':
                        options.page = parseFloat(value);
                        break;

                    case 'limit':
                        options.limit = parseFloat(value);
                        break;

                    case 'sort':
                        options.sort = value;
                        break;

                    case 'keyword':
                        options.keyword = decodeURIComponent(value);
                        break;

                    default:
                        const mr = key.match(/^filter_([-_A-Za-z0-9]+)$/);

                        if (!mr) {
                            break;
                        }

                        const filterSlug = mr[1];

                        if (options.filters === undefined) {
                            options.filters = {};
                        }

                        options.filters[filterSlug] = value;
                }
            }
        });
    }

    return options;
}

export function reloadCurrentRoute(router: Router) {
    void router.navigate([router.url]);
}

export function urlWithoutFilters(input: string): string {
    const index = input.lastIndexOf('/');
    if (index !== -1) {
        const filters = input.substring(index);
        if (filters.includes('=')) {
            return input.substring(0, index);
        }
    }
    return input;
}
