import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GarageService } from '../../../../services/garage.service';
import { AnchorRouterDirective } from '../../directives/anchor-router.directive';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { reloadCurrentRoute } from '../../../../functions/utils';
import { Router } from '@angular/router';
import { UserVehicle } from '../../../../interfaces/vehicle';

@Component({
    selector: 'app-garage-vehicle-selector',
    standalone: true,
    imports: [CommonModule, AnchorRouterDirective, IconComponent, TranslateModule],
    templateUrl: './garage-vehicle-selector.component.html',
    styleUrls: ['./garage-vehicle-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GarageVehicleSelectorComponent {
    @Input() wrapAsPopUp = false;
    @Input() showPopUp = false;

    @Output() selected: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        protected garage: GarageService,
        private router: Router,
    ) {}

    @HostBinding('class.garage-vehicle-selector_pop-up') get classPopUp(): boolean {
        return this.wrapAsPopUp;
    };
    @HostBinding('class.show') get classShow(): boolean {
        return this.showPopUp;
    };

    async selectVehicle(car: UserVehicle) {
        await this.garage.setCurrentVehicle(car);
        this.selected.emit();
        reloadCurrentRoute(this.router);
    }

    async removeVehicle(car: UserVehicle) {
        const isCurrentCar = this.garage.isCurrentCar(car);
        await this.garage.removeCar(car);
        if (isCurrentCar) {
            reloadCurrentRoute(this.router);
        }
    }

    async addNewVehicle() {
        await this.garage.selectNewVehicle();
        this.selected.emit();
    }
}
