import { Directive, HostListener, Input } from '@angular/core';
import { AnchorRouterService } from '../../../services/anchor-router.service';

@Directive({
  selector: '[appAnchorRouter]',
  standalone: true,
})
export class AnchorRouterDirective {

    @Input() anchorId: string = '';
    @Input() fallbackRoute: string = '/';
    @Input() offsetY: number = 0;

    constructor(private anchorRouter: AnchorRouterService) {}

    @HostListener('click', ['$event'])
    onClick(event: Event): void {
        event.preventDefault();

        if (this.anchorId) {
            this.anchorRouter.scrollToAnchor(
                this.anchorId,
                this.fallbackRoute,
                this.offsetY,
            );
        }
    }

}
