import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding, Inject,
    NgZone,
    OnDestroy,
    OnInit, PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { MobileMenuService } from '../../../../services/mobile-menu.service';
import { Subject } from 'rxjs';
import { CartService } from '../../../../services/cart.service';
import { GarageService } from '../../../../services/garage.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UrlService } from '../../../../services/url.service';
import { fromOutsideClick } from '../../../../functions/rxjs/from-outside-click';
import { filter, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    changeVehicleShowPopUp = false;

    @HostBinding('class.mobile-header') classMobileHeader = true;

    @ViewChild('searchForm') searchForm!: ElementRef<HTMLElement>;

    @ViewChild('searchInput') searchInput!: ElementRef<HTMLElement>;

    constructor(
        public menu: MobileMenuService,
        public cart: CartService,
        public garage: GarageService,
        private router: Router,
        private localize: LocalizeRouterService,
        public url: UrlService,
        private zone: NgZone,
        private elementRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        @Inject(PLATFORM_ID) protected platformId: any,
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                fromOutsideClick(this.elementRef.nativeElement).pipe(
                    filter(() => this.changeVehicleShowPopUp),
                    takeUntil(this.destroy$),
                ).subscribe(() => {
                    this.zone.run(() => {
                        this.closeChangeVehicle();
                        this.cdr.markForCheck();
                    });
                });
            });
        }
    }

    closeChangeVehicle() {
        this.changeVehicleShowPopUp = false;
    }

    toggleChangeVehicle() {
        this.changeVehicleShowPopUp = !this.changeVehicleShowPopUp;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSearch(term: string) {
        if (term) {
            const route = this.localize.translateRoute(this.url.search(term));
            this.router.navigate([route as string]).then();
        }
    }
}
