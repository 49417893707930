import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AnchorRouterService {
    constructor(
        private router: Router,
        private zone: NgZone,
    ) {
    }

    /**
     * Выполняет плавный скролл к указанному якорю
     * Основное использование - из директивы AnchorRouterDirective (shared)
     *
     * @param anchor ID элемента для скролла (без символа #)
     * @param fallbackRoute Маршрут для перехода, если якорь не найден
     * @param offsetY Смещение скролла до позиции
     */
    public scrollToAnchor(anchor: string, fallbackRoute: string = '/', offsetY: number = 0): void {
        // Проверяем, существует ли элемент с таким ID на текущей странице
        const element = document.getElementById(anchor);

        if (element) {
            // Если элемент существует, выполняем плавный скролл с учетом смещения
            this._scrollToElement(element, offsetY);
        } else {
            // Если элемент не существует, переходим на указанный маршрут
            this.router.navigate([fallbackRoute]).then(() => {
                // После загрузки страницы, пытаемся найти элемент и выполнить скролл
                this.zone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.zone.run(() => {
                            const targetElement = document.getElementById(anchor);
                            if (targetElement) {
                                this._scrollToElement(targetElement, offsetY);
                            }
                        })
                    }, 500);
                })
            });
        }
    }

    /**
     * Скроллит к элементу с учетом верхнего смещения
     */
    private _scrollToElement(element: HTMLElement, offsetY: number): void {
        // const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        // const offsetPosition = elementPosition - offsetY;
        //
        // window.scrollTo({
        //     top: offsetPosition,
        //     behavior: 'smooth',
        // });
        this.zone.runOutsideAngular(() => {
            // const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            // const offsetPosition = elementPosition - offsetY;
            //
            // window.scrollTo({
            //     top: offsetPosition,
            //     behavior: 'smooth',
            // });
            requestAnimationFrame(() => {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = elementPosition - offsetY;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            });
        });

    }
}
