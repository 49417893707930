import {
    Component,
    ElementRef,
    HostBinding,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';
import { DepartmentsLink } from '../../../../interfaces/departments-link';
import { fromOutsideClick } from '../../../../functions/rxjs/from-outside-click';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ShopCategoriesService } from '../../../../services/shop-categories.service';
import { ShopCategory } from '../../../../interfaces/category';
import { UrlService } from '../../../../services/url.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Megamenu, MegamenuColumn } from '../../../../interfaces/menu';

@Component({
    selector: 'app-departments',
    templateUrl: './departments.component.html',
    styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    isOpen = false;

    items: DepartmentsLink[] = [];

    currentItem: DepartmentsLink|null = null;

    @HostBinding('class.departments') classDepartments = true;

    @HostBinding('class.departments--open') get classDepartmentsOpen() {
        return this.isOpen;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private elementRef: ElementRef<HTMLElement>,
        private zone: NgZone,
        private categories: ShopCategoriesService,
        private url: UrlService,
        private localize: LocalizeRouterService,
    ) { }

    ngOnInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.categories.categories$
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => this.mapCategories(value));

        this.zone.runOutsideAngular(() => {
            fromOutsideClick(this.elementRef.nativeElement).pipe(
                filter(() => this.isOpen),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                this.zone.run(() => this.isOpen = false);
            });
        });
    }

    mapCategories(categories: ShopCategory[]) {
        this.items = categories.map<DepartmentsLink>(category => {
            return {
                title: category.name,
                url: this.localize.translateRoute(this.url.category(category)) as string,
                submenu: category.children?.length
                    ? this.mapToSubmenu(category)
                    : undefined,
            }
        });
    }

    mapToSubmenu(category: ShopCategory): Megamenu {
        const group = category.children?.filter(x => x.children && x.children.length) || [];
        const categories = category.children?.filter(x => !x.children || !x.children.length) || [];
        const columns: MegamenuColumn[] = [
            <MegamenuColumn>{
                size: 4,
                links: categories.map(x => ({
                    title: x.name,
                    url: this.localize.translateRoute(this.url.category(x)) as string,
                })),
            },
            ...group.map(x => (<MegamenuColumn>{
                size: 4,
                links: [
                    {
                        title: x.name,
                        url: this.localize.translateRoute(this.url.category(x)) as string,
                        links: x.children?.slice(0, 16).map(child => ({
                            title: child.name,
                            url: this.localize.translateRoute(this.url.category(child)) as string,
                        })),
                    },
                ],
            })),
        ];

        return {
            size: 'lg',
            columns: columns,
            image: category.image || undefined,
            type: 'megamenu',
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onClick() {
        this.isOpen = !this.isOpen;
    }

    onMouseenter(item: DepartmentsLink) {
        this.currentItem = item;
    }

    onMouseleave() {
        this.currentItem = null;
    }

    onItemClick(): void {
        this.isOpen = false;
        this.currentItem = null;
    }
}
