import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { GarageService } from '../../../../services/garage.service';

@Component({
    selector: 'app-empty-garage-menu',
    templateUrl: './empty-garage-menu.component.html',
    styleUrls: ['./empty-garage-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyGarageMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.empty-garage-menu') classAccountMenu = true;

    constructor(private garage: GarageService) {
    }

    selectVehicle() {
        this.garage.setPickerState(true);
        this.closeMenu.emit();
    }
}
