<form class="search__body">
    <div class="search__shadow"></div>

    <label class="sr-only" for="site-search">{{ 'INPUT_SEARCH_LABEL'|translate }}</label>
    <div class="site-search__field">
        <input
            type="text"
            id="site-search"
            autocapitalize="off"
            autocomplete="off"
            spellcheck="false"
            name="search"
            class="search__input"
            placeholder="{{'INPUT_SEARCH_PLACEHOLDER'|translate}}"
            (focus)="onInputFocus($event)"
            (input)="onInput($event)"
            (keydown.enter)="search.blur(); onSearch(search.value)"
            #search
        >
        <button class="search__button search__button--end" type="submit" (click)="onSearch(search.value)">
        <span class="search__button-icon">
            <app-icon *ngIf="!inSearch" icon="search-20"></app-icon>
            <div *ngIf="inSearch" class="search_button-loader loader"></div>
        </span>
        </button>
    </div>

    <div class="search__box"></div>
    <div class="search__decor">
        <div class="search__decor-start"></div>
        <div class="search__decor-end"></div>
    </div>

    <div
        class="search__dropdown search__dropdown--suggestions suggestions"
        [class.search__dropdown--open]="suggestionsIsOpen && hasSuggestions"
    >
        <div class="suggestions__group" *ngIf="products.length > 0">
            <div class="suggestions__group-title">{{ 'TEXT_PRODUCTS'|translate }}</div>
            <div class="suggestions__group-content">
                <a
                    *ngFor="let product of products"
                    class="suggestions__item suggestions__product"
                    [routerLink]="url.product(product)|localize"
                    (click)="toggleSuggestions(false)"
                >
                    <div class="suggestions__product-image">
                        <img [src]="product|getProductImage" alt="">
                    </div>
                    <div class="suggestions__product-info">
                        <div class="suggestions__product-name">
                            {{ product.name + ' (' + product.brand?.name + ')'}}
                        </div>
                        <div class="suggestions__product-rating">
                            <div class="suggestions__product-rating-label">
                                <b>{{'TEXT_OKT'|translate}}: </b>{{product.partNumber}}
                            </div>
                            <div class="suggestions__product-rating-label">
                                <b>{{'TEXT_ARTICLE'|translate}}: </b>{{product.sku}}
                            </div>
                            <div *ngIf="product.replace" class="suggestions__product-rating-label">
                                <b>{{'TEXT_REPLACE_CROSS'|translate:{cross: product.replace.source} }}</b>
                                <span *ngIf="product.replace.different">{{product.replace.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="suggestions__product-price">
                        <ng-container *ngIf="!product.crossDummy; else availableToOrder">
                            {{ product.price|currencyFormat }}
                        </ng-container>
                    </div>
                </a>
            </div>
        </div>
        <div class="suggestions__group" *ngIf="categories.length > 0">
            <div class="suggestions__group-title">{{ 'TEXT_CATEGORIES'|translate }}</div>
            <div class="suggestions__group-content">
                <a
                    *ngFor="let category of categories"
                    class="suggestions__item suggestions__category"
                    [routerLink]="url.category(category)|localize"
                    (click)="toggleSuggestions(false)"
                >
                    {{ category.name }}
                </a>
            </div>
        </div>
    </div>
</form>

<ng-template #availableToOrder>
    <span class="color-main">{{'TEXT_AVAILABLE_TO_ORDER'|translate}}</span>
</ng-template>
