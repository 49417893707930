import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { VehicleApi } from '../api';
import { ModificationVehicle, UserVehicle } from '../interfaces/vehicle';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './login.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
    providedIn: 'root',
})
export class GarageService {
    private destroy$: Subject<void> = new Subject<void>();

    private _carsSubject$: BehaviorSubject<UserVehicle[]> = new BehaviorSubject<UserVehicle[]>([]);

    private _currentVehicle$: BehaviorSubject<UserVehicle | undefined> = new BehaviorSubject<UserVehicle | undefined>(undefined);

    public currentVehicle$ = this._currentVehicle$.asObservable();

    private _pickerActivated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public pickerActivated$ = this._pickerActivated$.asObservable();

    public cars$ = this._carsSubject$.asObservable();

    public hasCars$!: Observable<boolean>;

    private readonly localKey = 'garage';
    private readonly localKeyCurrent = 'currentVehicle';

    constructor(
        private api: VehicleApi,
        @Inject(PLATFORM_ID) private platformId: any,
        private toastr: ToastrService,
        private translate: TranslateService,
        private login: LoginService,
        private cookieService: SsrCookieService,
    ) {
        if (isPlatformBrowser(platformId)) {
            this._getGarage();

            this.hasCars$ = this.cars$.pipe(map(x => x.length > 0));

            this.login.login$
                .pipe(takeUntil(this.destroy$), filter(value => value))
                .subscribe(value => {
                    if (value) {
                        this._getGarage(true);
                    }
                });

            this.login.signOut$
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    void this._resetLocalData();
                });
        } else {
            /**
             * На стороне сервера устанавливаем только текущий транспорт из cookie
             */
            this.loadCurrentVehicle();
        }
    }

    private _getGarage(remote = false) {
        if (remote) {
            this.api.getGarage()
                .pipe(take(1))
                .subscribe(value => {
                    if (value.length) {
                        this._joinCars(value);
                        this._saveLocalStorage();
                        this.loadCurrentVehicle();
                    }
                });
        } else {
            this._loadLocalStorage();
            this.loadCurrentVehicle();
        }
    }

    private _joinCars(cars: UserVehicle[]) {
        const existingCars = this._carsSubject$.value;
        this._carsSubject$.next(
            [
                ...existingCars,
                ...cars.filter(car => !existingCars.some(x => x.uid === car.uid)),
            ],
        );
    }

    private async _resetLocalData() {
        await this.setCurrentVehicle(null);
        this._carsSubject$.next([]);
        this._saveLocalStorage();
    }

    private _loadLocalStorage() {
        const items = localStorage.getItem(this.localKey);
        if (items) {
            this._carsSubject$.next(JSON.parse(items));
        }
    }

    private _saveLocalStorage() {
        const cars = this._carsSubject$.value;
        localStorage.setItem(this.localKey, JSON.stringify(cars));
    }

    get cars(): UserVehicle[] {
        return this._carsSubject$.value;
    }

    async addCar(car: ModificationVehicle, setCurrent = true, plate = '', vin = '') {
        let vehicle = car as UserVehicle;
        vehicle.vin = vin;
        vehicle.plate = plate;

        this._carsSubject$.next([...this._carsSubject$.value, vehicle]);

        this._saveLocalStorage();

        if (setCurrent) {
            await this.setCurrentVehicle(vehicle);
        }

        if (this.login.isLoggedIn) {
            void firstValueFrom(this.api.addVehicle(vehicle.id, vehicle.uid, vin, plate));
            this.toastr.success(this.translate.instant('TEXT_ADD_CAR_SUCCESS'));
        }
    }

    isCurrentCar(car: UserVehicle): boolean {
        return this.currentVehicle?.uid == car.uid;
    }

    async removeCar(car: UserVehicle) {
        let values = this._carsSubject$.value.filter(value => value.uid !== car.uid);
        this._carsSubject$.next(values);
        if (this.isCurrentCar(car)) {
            await this.setCurrentVehicle(null);
        }
        this._saveLocalStorage();
        this.api.removeVehicle(car.id, car.uid).pipe(take(1)).subscribe();
    }

    saveCar(car: UserVehicle) {
        this._carsSubject$.next(this._carsSubject$.value);
        this._saveLocalStorage();
        this.api.saveUserCarData(car).pipe(take(1)).subscribe();
    }

    get currentVehicle(): UserVehicle | undefined {
        return this._currentVehicle$.value;
    }

    async selectNewVehicle() {
        await this.setCurrentVehicle(null);
        this.setPickerState(true);
    }

    async setCurrentVehicle(vehicle: UserVehicle | null, save = true): Promise<void> {
        if (isPlatformBrowser(this.platformId)) {
            if (vehicle) {
                const car = this._carsSubject$.value.find(({ uid }) => uid === vehicle.uid);
                this._currentVehicle$.next(car);
                this.setPickerState(false);
            } else {
                this._currentVehicle$.next(undefined);
            }
        } else {
            this._currentVehicle$.next(vehicle ?? undefined);
        }

        if (save) {
            await this.saveCurrentVehicle();
        }
    }

    private async saveCurrentVehicle(): Promise<void> {
        const vehicle = this._currentVehicle$.value;

        // для сохранения в сессии
        // await firstValueFrom(this.api.setCurrentVehicle(vehicle || null));

        //для сохранения в куки
        this.cookieService.set(this.localKeyCurrent, JSON.stringify(vehicle ?? null), undefined, '/');
    }

    loadCurrentVehicle() {
        //для загрузки из куки
        if (this.cookieService.check(this.localKeyCurrent)) {
            try {
                const currentVehicle = this.cookieService.get(this.localKeyCurrent);
                const parsedVehicle = JSON.parse(currentVehicle);

                if (this._isValidSavedVehicle(parsedVehicle)) {
                    void this.setCurrentVehicle(parsedVehicle);
                } else {
                    // console.warn('Invalid vehicle data in cookies');
                    void this.setCurrentVehicle(null, true);
                }
            } catch (error) {
                // console.error('Error parsing vehicle from cookies:', error);
                void this.setCurrentVehicle(null, true);
            }
        }

        //для загрузки из сессии
        // this.api.getCurrentVehicle().pipe(take(1)).subscribe(value => {
        //     void this.setCurrentVehicle(value, false);
        // });
    }

    private _isValidSavedVehicle(obj: any): obj is UserVehicle {
        return obj && typeof obj === 'object' && 'uid' in obj && typeof obj.uid === 'string';
    }

    setPickerState(state: boolean) {
        this._pickerActivated$.next(state);
    }
}
