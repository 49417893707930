import { Observable } from 'rxjs';
import { Slide } from '../../interfaces/slide';
import { Link } from '../../interfaces/link';
import { Category } from '../../interfaces/category';
import { Brand } from '../../interfaces/brand';
import { BaseVehicle, FeaturedCars } from '../../interfaces/vehicle';
import { CommonItn } from '../../interfaces/common-itn';
import { Catalog } from '../../interfaces/catalog';

export interface AvailableOptions {
    brand?: string;
    carBrand?: string;
    carModel?: string;
    category?: string;
}

export abstract class CommonsApi {
    abstract getSlides(): Observable<Slide[]>;

    abstract getFile(slug: string): Observable<Link>;

    abstract getAvailableCategories(options: AvailableOptions): Observable<Category[]>;

    abstract getBrand(slug: string): Observable<Brand>;

    abstract getAvailableVehicles(options: AvailableOptions): Observable<FeaturedCars>;

    abstract getAvailableModels(options: AvailableOptions): Observable<BaseVehicle[]>;

    abstract getFeaturedBrands(): Observable<Brand[]>;

    abstract getAvailableBrands(options: AvailableOptions): Observable<Brand[]>;

    abstract getAllCars(): Observable<FeaturedCars>;

    abstract getPdv(pdv: string): Observable<CommonItn>

    abstract getCatalog(slug?: string, car?: string): Observable<Catalog>;
}
