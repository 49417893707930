<div class="empty-garage-menu__wrapper">
    <button
        appAnchorRouter
        [anchorId]="'carPicker'"
        [fallbackRoute]="'/'"
        [offsetY]="150"
        class="btn btn-primary default-pargo-button-inverse justify-content-center"
        (click)="selectVehicle()"
    >
        <app-icon icon="car-24"></app-icon>
        <span class="text-center">{{ 'BUTTON_SEARCH_SELECT_VEHICLE_DESKTOP' | translate }}</span>
    </button>
</div>
