import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { ShopCategory } from '../../interfaces/category';
import { Bom, Product, ProductReport, ProductRequest, ProductWait } from '../../interfaces/product';
import { ProductsList, ReviewsList } from '../../interfaces/list';
import {
    GetCategoriesOptions,
    GetCategoryBySlugOptions,
    GetProductReviewsOptions,
    GetProductsListOptions,
    GetSearchSuggestionsOptions,
    GetSearchSuggestionsResult,
    ShopApi,
} from '../base';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseVehicle, GroupVehicle, ModificationVehicle } from '../../interfaces/vehicle';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class RestShopApi extends ShopApi {

    url = environment.apiHost;

    constructor(
        private http: HttpClient,
    ) {
        super();
    }

    getCategoryBySlug(slug: string, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
        let params = new HttpParams().set('slug', slug);

        if(options?.depth) {
            params = params.set('depth', options.depth);
        }

        const url = `${this.url}/product/category`;
        return this.http.get<ShopCategory[]>(url, { params: params }).pipe(
            map(categories => categories[0]),
        );
    }

    getCategories(options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        let url = `${this.url}/product/category`;
        let params = new HttpParams()
        if (options?.slugs && options?.slugs.length) {
            params = params.append('slug', options.slugs[0]);
        }
        if (options?.depth) {
            params = params.append('depth', options.depth);
        }
        if (options?.withParent !== undefined) {
            params = params.append('with-parents', options.withParent);
        }
        return this.http.get<ShopCategory[]>(url, { params: params });
    }

    getProductsList(options?: GetProductsListOptions): Observable<ProductsList> {
        let url = `${this.url}/product/products`;

        let params = new HttpParams();

        if (options?.targetKeyword) {
            params = params.append('vehicle', options.targetKeyword)
        }
        if (options?.category) {
            params = params.append('category', options.category)
        }
        if (options?.page) {
            params = params.append('page', options.page)
        }
        if (options?.limit) {
            params = params.append('size', options.limit)
        } else {
            params = params.append('size', 16)
        }
        if (options?.sort) {
            params = params.append('sort', options.sort)
        }
        if (options?.keyword) {
            params = params.append('keyword', options.keyword)
        }
        if (options?.article){
            params = params.append('article', options.article)
        }
        if (options?.filters?.['car-brand']) {
            const carBrands = (options.filters['car-brand'] as string).split(',');
            if (carBrands) {
                carBrands.forEach((item) => {
                    params = params.append('car-brand[]', item)
                });
            }
        }
        if (options?.filters?.['car-year']) {
            const carBrands = (options.filters['car-year'] as string).split(',');
            if (carBrands) {
                carBrands.forEach((item) => {
                    params = params.append('car-year[]', item)
                });
            }
        }
        if (options?.filters?.['car-model']) {
            const carBrands = (options.filters['car-model'] as string).split(',');
            if (carBrands) {
                carBrands.forEach((item) => {
                    params = params.append('car-model[]', item)
                });
            }
        }
        if (options?.filters?.['car']) {
            const carBrands = (options.filters['car'] as string).split(',');
            if (carBrands) {
                carBrands.forEach((item) => {
                    params = params.append('car[]', item)
                });
            }
        }
        if (options?.filters?.['constructive']) {
            const slugs = (options.filters['constructive'] as string).split(',');
            if (slugs) {
                slugs.forEach((item) => {
                    params = params.append('constructives[]', item)
                });
            }
        }
        if (options?.filters?.['condition']) {
            const slugs = (options.filters['condition'] as string).split(',');
            if (slugs) {
                slugs.forEach((item) => {
                    params = params.append('conditions[]', item)
                });
            }
        }
        if (options?.filters?.['markdown']) {
            const slugs = (options.filters['markdown'] as string).split(',');
            if (slugs) {
                slugs.forEach((item) => {
                    params = params.append('markdowns[]', item)
                });
            }
        }
        if (options?.filters?.['note']) {
            const slugs = (options.filters['note'] as string).split(',');
            if (slugs) {
                slugs.forEach((item) => {
                    params = params.append('notes[]', item)
                });
            }
        }
        if (options?.brand) {
            params = params.append('brand', options.brand)
        }
        if (options?.filters?.['brand']) {
            const brands = (options.filters['brand'] as string).split(',');
            if (brands) {
                brands.forEach((item) => {
                    params = params.append('brands[]', item)
                });
            }
        }
        if (options?.carBrand) {
            params = params.append('page-vehicle-brand', options.carBrand)
        }
        if (options?.carModel) {
            params = params.append('page-vehicle-model', options.carModel)
        }
        if (options?.filters?.['price']) {
            const prices = (options.filters['price'] as string).split('-');
            if (prices) {
                params = params.append('from_price', prices[0])
                params = params.append('to_price', prices[1])
            }
        }
        for (const filter in options?.filters) {
            if (filter.includes('property-range')) {
                const regex = /property-range_(.+)/;
                const match = filter.match(regex);
                if (match) {
                    const id = match[1];
                    const value = options!.filters[filter];
                    const values = value.split('-').map((e) => {
                        return e === 'NaN' ? '' : e;
                    });
                    if (values) {
                        params = params.append(`property-range[${id}][from]`, values[0])
                        params = params.append(`property-range[${id}][to]`, values[1])
                    }
                }
            } else if (filter.includes('property')) {
                const regex = /property_(.+)/;
                const match = filter.match(regex);
                if (match) {
                    const id = match[1];
                    const value = options!.filters[filter];
                    if (value) {
                        params = params.append(`property[${id}][]`, value)
                    }
                }
            }
        }
        return this.http.get<ProductsList>(url, { params });
    }

    getProductBySlug(slug: string, dummy: boolean): Observable<Product> {
        // return getProductBySlug(slug);
        // const productId = slug.split('-')[0];
        const url = `${this.url}/product/product`;
        let params = new HttpParams().set('slug', slug);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<Product>(url, { params: params });
    }

    getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
        // return getProductReviews(productId, options);
        const url = `${this.url}/product/review`;
        const params = new HttpParams().set('id', productId)
        return this.http.get<ReviewsList>(url, { params });
    }

    getProductAnalogs(productId: number, dummy: boolean): Observable<Product[]> {
        const url = `${this.url}/product/analogs`;
        let params = new HttpParams().set('id', productId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<Product[]>(url, { params });
    }

    getProductReplaces(productId: number, dummy: boolean): Observable<Product[]> {
        const url = `${this.url}/product/replaces`;
        let params = new HttpParams().set('id', productId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<Product[]>(url, { params });
    }

    getLatestProducts(limit: number): Observable<Product[]> {
        let url = `${this.url}/product/top-novelty`;
        const params = new HttpParams()
            .set('limit', limit)
        return this.http.get<Product[]>(url, { params: params });
    }

    getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult> {
        if (!query) {
            return of({
                categories: [],
                products: [],
            });
        }
        let url = `${this.url}/product/search`;

        let params = new HttpParams()
            .set('term', query)
            .set('product_limit', options?.limitProducts!)
            .set('categories_limit', options?.limitCategories!);

        return this.http.get<GetSearchSuggestionsResult>(url, { params: params });
    }

    addToWaitList(productId: number): Observable<any> {
        const url = `${this.url}/product/add-wait-list`;
        const body = {
            id: productId,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(body), { headers: headers });
    }

    removeFromWaitList(productId: number): Observable<any> {
        const url = `${this.url}/product/remove-wait-list`;
        const body = {
            id: productId,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(body), { headers: headers });
    }

    getWaitList(onlyAvailable?: boolean): Observable<ProductWait[]> {
        const url = `${this.url}/product/load-wait-list`;
        let params = new HttpParams();
        if (onlyAvailable) {
            params = new HttpParams()
                .set('onlyAvailable', onlyAvailable)
        }
        return this.http.get<ProductWait[]>(url, { params: params });
    }

    clearWaitList(): Observable<any> {
        const url = `${this.url}/product/clear-wait-list`;
        return this.http.get(url);
    }

    getBom(articleId: number, productCode?: string): Observable<Bom[]> {
        const url = `${this.url}/product/bom`;
        let params = new HttpParams()
            .set('article_id', articleId);

        if (productCode) {
            params = params.append('product_code', productCode);
        }
        return this.http.get<Bom[]>(url, { params });
    }

    getApplicability(productId: number): Observable<ModificationVehicle[]> {
        const url = `${this.url}/product/applicability`;
        const params = new HttpParams().set('id', productId);
        return this.http.get<ModificationVehicle[]>(url, { params });
    }

    productReport(report: ProductReport): Observable<any> {
        const url = `${this.url}/product/report`;
        const params = new HttpParams().set('product_id', report.productId);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(report), { headers: headers, params: params });
    }

    getApplicabilityBrands(productId: number, dummy: boolean): Observable<BaseVehicle[]> {
        const url = `${this.url}/product/applicability-brands`;
        let params = new HttpParams()
            .set('productId', productId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<BaseVehicle[]>(url, { params: params });
    }

    getApplicabilityModels(productId: number, brandId: number, dummy: boolean): Observable<GroupVehicle[]> {
        const url = `${this.url}/product/applicability-models`;
        let params = new HttpParams()
            .set('productId', productId)
            .set('brandId', brandId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<GroupVehicle[]>(url, { params: params });
    }

    productRequest(request: ProductRequest): Observable<any> {
        const url = `${this.url}/product/request`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(request), { headers });
    }
}
