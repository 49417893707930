<div class="mobile-menu__wrapper-list">
    <span *ngIf="items?.length === 1" class="icon">
        <app-icon icon="category-25"></app-icon>
    </span>
    <ul>
        <li *ngFor="let item of items" style="color: rgba(255, 255, 255, 0.7)">
            <ng-template #content>
                <div *ngIf="item.image" class="mobile-menu__links-image">
                    <img src="{{ item.image }}" alt="" />
                </div>
                {{ item.title }}
                <app-icon *ngIf="item.submenu" icon="arrow-rounded-right-7x11"></app-icon>
            </ng-template>

            <ng-container *ngIf="item.url && (item.external || item?.submenu?.length)">
                <a
                    [attr.id]="item.url === '/' ? 'rootCategory' : undefined"
                    [attr.target]="item.external ? '_blank' : undefined"
                    [href]="item.url"
                    [class.highlight]="item.customFields && item.customFields['highlight']"
                    (click)="onItemClick($event, item, panel)"
                >
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </a>
            </ng-container>

            <ng-container
                *ngIf="item.url && !(item.external || item?.submenu?.length)"
            >
                <a
                    [routerLink]="item.url"
                    [class.highlight]="item.customFields && item.customFields['highlight']"
                    (click)="onItemClick($event, item, panel)"
                >
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </a>
            </ng-container>

            <ng-container *ngIf="!item.url">
                <button
                    type="button"
                    [class.highlight]="item.customFields && item.customFields['highlight']"
                    (click)="onItemClick($event, item, panel)"
                >
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </button>
            </ng-container>

            <ng-template #panel>
                <app-mobile-menu-links
                    *ngIf="item.submenu"
                    [items]="item.submenu"
                    (itemClick)="itemClick.emit($event)"
                ></app-mobile-menu-links>
            </ng-template>
        </li>
    </ul>
</div>
