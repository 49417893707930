import { Injectable, OnDestroy } from '@angular/core';
import { ShopCategory } from '../interfaces/category';
import { ReplaySubject, Subject } from 'rxjs';
import { ShopApi } from '../api';
import { LanguageService } from '../modules/language/services/language.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ShopCategoriesService implements OnDestroy {
    private destroy$: Subject<void > = new Subject<void>();

    private categoriesSubject = new ReplaySubject<ShopCategory[]>(1);
    categories$ = this.categoriesSubject.asObservable();

    constructor(private shopApi: ShopApi, private language: LanguageService) {
        this.language.currentChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
            this.loadCategories();
        });
        this.loadCategories();
    }

    // get categories(): ShopCategory[] {
    //     return this.categoriesSubject.value;
    // }

    private loadCategories() {
        this.shopApi.getCategories({ depth: 0, withParent: 0 })
            .pipe(takeUntil(this.destroy$))
            .subscribe(categories => this.categoriesSubject.next(categories));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
