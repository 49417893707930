<!--<div class="snowflake-wrapper h-100 w-100 position-absolute overflow-hidden"></div>-->
<app-snow></app-snow>
<div class="container">
    <div class="header">
        <div class="header__topbar-pargo-bg"></div>
        <div class="header__topbar-pargo">
            <app-topbar layout="pargo"></app-topbar>
        </div>

        <app-logo class="header__logo"></app-logo>
<!--        <div class="header__departments">-->
<!--            <app-departments></app-departments>-->
<!--        </div>-->
        <div class="header__search">
            <app-departments></app-departments>
            <app-search></app-search>
        </div>
        <div class="header__indicators">
            <app-indicator
                *ngIf="garage.hasCars$|async; else emptyGarage"
                icon="garage-46"
                trigger="click"
                contentPosition="left"
                [label]="'Гараж'"
                [value]="(garage.cars$|async)?.length?.toString() || '0'"
                [withIconBorder]="false"
                #garageIndicator
            >
                <app-garage-vehicle-selector
                    (selected)="garageIndicator.close()">
                </app-garage-vehicle-selector>
            </app-indicator>

            <ng-template #emptyGarage>
                <app-indicator
                    icon="car-24"
                    trigger="click"
                    [contrastBorder]="true"
                    contentPosition="left"
                    #emptyGarageIndicator
                >
                    <app-empty-garage-menu (closeMenu)="emptyGarageIndicator.close()">
                    </app-empty-garage-menu>
                </app-indicator>
            </ng-template>

            <app-indicator
                    icon="cart-19"
                    [label]="'TEXT_INDICATOR_CART_LABEL'|translate"
                    [value]="((cart.quantity$|async) || 0) + ''"
                    trigger="click"
                    (click)="cart.open()"
            >
            </app-indicator>

            <button *ngIf="(loginService.login$|async) !== true; else loggedInIndicator" class="header-login-button" (click)="loginService.open()">
                <span>{{'BUTTON_LOGIN'|translate|uppercase}}</span>
                <span class="header-login-button__secondary">{{'BUTTON_LOGIN_SUBTITLE'|translate}}</span>
            </button>

            <ng-template #loggedInIndicator>
                <app-indicator
                    *ngIf="(loginService.login$|async) === true"
                    link="/account"
                    icon="person-19"
                    [label]="(userName$|async) || ('TEXT_INDICATOR_ACCOUNT_LABEL'|translate)"
                    [value]="'TEXT_INDICATOR_ACCOUNT_VALUE'|translate"
                    trigger="click"
                    #accountIndicator="indicator"
                >
                    <app-account-menu (closeMenu)="accountIndicator.close()"></app-account-menu>
                </app-indicator>
            </ng-template>
        </div>
    </div>
</div>
